import { PlatformOS } from '../constants'

export function getMobileOperatingSystem(): PlatformOS {
  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator

    if (/android/i.test(userAgent)) {
      return PlatformOS.ANDROID
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return PlatformOS.IOS
    }
  }

  return PlatformOS.UNKNOWN
}
