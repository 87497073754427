import './src/styles/normalize.css'
import './src/styles/global.css'
import 'swiper/css'
import 'swiper/css/pagination'

import type { GatsbyBrowser } from 'gatsby'
import { setViewportFullHeight } from './src/helpers/setViewportFullHeight'
import { firebaseService } from './src/event-logger/services/firebase.service'
import { LOGGER_WAS_INITIALIZED } from './src/constants'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  sessionStorage.removeItem(LOGGER_WAS_INITIALIZED)
  setViewportFullHeight()
  await firebaseService.init()
}
