import amplitude from 'amplitude-js'
import { IEventLogger, IEventLogParams, TCommonEventProperties } from '../types'
import { EventLoggerInstanceName } from '../constants'
import { getOperatingSystemVersion } from '../../helpers/getOperatingSystemVersion'
import { getMobileOperatingSystem } from '../../helpers/getMobileOperatingSystem'

interface IAmplitude {
  getInstance: (amplitudeInstanceName?: string) => typeof amplitude
  init: (
    apiKey: string,
    userId: string | null,
    options?: Record<string, unknown>,
  ) => void
  setUserId: (userId: string) => void
  setUserProperties: (properties: Record<string, string>) => void
  logEvent: (
    event: string,
    eventProperty?: Record<string, unknown>,
    cb?: () => void,
  ) => void
}

export class Amplitude implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly amplitude: IAmplitude
  private readonly isProdEnvironment: boolean

  constructor({
    instanceName,
    apiKey,
    options,
    isProdEnvironment,
  }: {
    instanceName: EventLoggerInstanceName
    apiKey: string
    options?: Record<string, unknown>
    isProdEnvironment: boolean
  }) {
    this.name = instanceName
    this.amplitude = amplitude.getInstance(instanceName)
    this.amplitude.init(apiKey, null, options)
    this.isProdEnvironment = isProdEnvironment
  }

  configure(): void {
    this.setUserProperties()
  }

  log({ event, eventProperty, cb }: IEventLogParams): void {
    const mergedEventProperty: TCommonEventProperties = {
      operation_system_version: getOperatingSystemVersion(),
      is_prod_env: this.isProdEnvironment,
      ...eventProperty,
    }

    this.amplitude.logEvent(event, mergedEventProperty, cb)
  }

  private setUserProperties(): void {
    const userProperties = {
      'Device Type': getMobileOperatingSystem(),
      'Operating System Version': getOperatingSystemVersion(),
    }
    this.amplitude.setUserProperties(userProperties)
  }
}
