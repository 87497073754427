export const getCommonViewportProperties = (): Record<
  string,
  string | number
> => {
  if (typeof window === 'undefined') return {}

  return {
    viewport_width: window.screen.width,
    viewport_height: window.screen.height,
    viewport_size: `${window.screen.width}x${window.screen.height}`,
  }
}
