import { PlatformOS } from '../constants'

export function getOperatingSystemVersion(): string {
  if (typeof window === 'undefined') return PlatformOS.UNKNOWN

  const { userAgent } = window.navigator
  const [operatingSystemVersion] = /[^()]+(?=\))/.exec(userAgent) || []

  return operatingSystemVersion
}
