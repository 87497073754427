import { Amplitude } from './amplitude.service'
import { EventLoggerInstanceName } from '../constants'
import { getGIAAmplitudeOptions } from '../helpers/getGIAAmplitudeOptions'
import { eventLogger } from './eventLogger.service'
import { getIsProdEnv } from '../../helpers/getIsProdEnv'
import { LOGGER_WAS_INITIALIZED } from '../../constants'

export const initEventLogger = () => {
  const isProdEnvironment = getIsProdEnv()
  const amplitudeService = new Amplitude({
    apiKey: '26bf9ad22cf44f0afda3593518c68564',
    instanceName: EventLoggerInstanceName.AMPLITUDE,
    isProdEnvironment,
  })

  const giaService = new Amplitude({
    apiKey: '232a07af-4bc0-4592-adeb-d5e80e38297a',
    instanceName: EventLoggerInstanceName.GIA,
    options: getGIAAmplitudeOptions(),
    isProdEnvironment,
  })

  amplitudeService.configure()
  giaService.configure()

  eventLogger.init(amplitudeService, giaService)

  sessionStorage.setItem(LOGGER_WAS_INITIALIZED, 'true')
}
