import { getIsProdEnv } from '../../helpers/getIsProdEnv'

export const getGIAAmplitudeOptions = () => {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint: getIsProdEnv()
      ? 'analytics.gismart.xyz/events'
      : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
  }
}
